import React from "react";

import { Col, Row, Button, Breadcrumb } from "antd";
import { ArrowLeft } from "react-iconly";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function PageTitle(props) {
  const { pageTitle, pageText, backButton, className, children, breadcrumbs } =
    props;

  const history = useHistory();
  return (
    <Col span={24} className={className}>
      {breadcrumbs && breadcrumbs.length > 0 && (
        <Breadcrumb className="vc-mb-16">
          {breadcrumbs.map((item, index) => (
            <Breadcrumb.Item key={index} href={item.link}>
              {item.label}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
      <Row>
        {backButton && (
          <Col>
            <Button
              type="text"
              onClick={() =>
                typeof backButton === "function"
                  ? backButton()
                  : history.goBack()
              }
              shape="circle"
              className="vc-text-color-black-100 vc-mr-8 vc-text-color-dark-10"
            >
              <ArrowLeft set="light" />
            </Button>
          </Col>
        )}
        <Col flex="auto">
          {pageTitle && <h2 className="vc-mb-8">{pageTitle}</h2>}
        </Col>
        <Col flex="auto" align="right">
          <Row span={24} justify="end">
            {children}
          </Row>
        </Col>

        <Col span={24}>
          {pageText && <p className="vc-mb-0 vc-p1-body">{pageText}</p>}
        </Col>
      </Row>
    </Col>
  );
}

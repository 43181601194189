import { useState } from "react";
import {
  Bookmark,
  Category,
  Delete,
  Document,
  Folder,
  Game,
  Message,
  Send,
  Setting,
  Star,
  Ticket,
  TicketStar,
  TimeCircle,
  User,
  Wallet,
  Work,
} from "react-iconly";
import { IoCard, IoCardOutline } from "react-icons/io5";
import { RiComputerLine, RiPencilLine } from "react-icons/ri";

const pages = [
  {
    header: "UTAMA",
    access: null,
  },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Category set="curved" className="remix-icon" />,
    navLink: "/dashboard",
    access: null,
  },
  {
    id: "mitra",
    title: "Mitra",
    icon: <Work set="curved" className="remix-icon" />,
    navLink: "/mitra/data",
    indicator: "mitra-indicator",
    access: ["05", "06"],
  },
  {
    id: "category",
    title: "Kategori",
    icon: <Bookmark set="curved" className="remix-icon" />,
    access: ["17", "23", "1701", "17001"],
    children: [
      {
        id: "category-group",
        title: "Grup Kategori",
        navLink: "/category/group",
        access: ["1701", "17014"],
      },
      {
        id: "category-data",
        title: "Kategori Produk",
        navLink: "/category/data",
        access: ["17001", "23"],
      },
      {
        id: "product-group",
        title: "Grup Produk",
        navLink: "/tier/product-group/data",
        access: null,
      },
      {
        id: "category-label",
        title: "Label Kategori",
        navLink: "/category/parent",
        access: null,
        // NOT SET
      },
      {
        id: "category-status",
        title: "Status Kategori Produk",
        navLink: "/category/status",
        access: null,
      },
    ],
  },
  {
    id: "product-price-history",
    title: "Produk",
    icon: <Game set="curved" className="remix-icon" />,
    access: ["27", "33"],
    // NOT SET FOR History Product
    children: [
      {
        id: "list-product",
        title: "List Produk",
        navLink: "/product/data",
        access: ["27", "33"],
      },
      {
        id: "history-price-supplier",
        title: "Riwayat Harga Supplier",
        navLink: "/product-price-history/supplier",
        access: null,
        // NOT SET
      },
      {
        id: "history-price-tier",
        title: "Riwayat Harga Tier",
        navLink: "/product-price-history/tier",
        access: null,
        // NOT SET
      },
    ],
  },
  {
    id: "transaction",
    title: "Transaksi",
    icon: <Document set="curved" className="remix-icon" />,
    access: null,
    children: [
      {
        id: "transaction-mitra",
        title: "Transaksi Mitra H2H",
        navLink: "/transaction/data",
        access: null,
      },
      {
        id: "transaction-panel",
        title: "Transaksi Mitra Panel",
        navLink: "/transaction/panel",
        access: null,
      },
    ],
  },
  {
    id: "topup",
    title: "Top Up",
    icon: <TicketStar set="curved" className="remix-icon" />,
    navLink: "/topup/data",
    access: ["68", "680"],
  },
  {
    id: "tier",
    title: "Tier",
    icon: <Star set="curved" className="remix-icon" />,
    navLink: "/tier/data",
    access: null,
    // NOT SET
  },
  {
    id: "referral",
    title: "Referral",
    icon: <Send set="curved" className="remix-icon" />,
    navLink: "/referral/data",
    access: null,
    // NOT SET
  },
  {
    header: "PANEL",
    access: null,
  },
  {
    id: "request-web",
    title: "Request Web",
    icon: <RiComputerLine set="curved" className="remix-icon" />,
    navLink: "/panel/request/data",
    access: null,
  },
  {
    id: "panel-saldo-profit",
    title: "Saldo Profit",
    icon: <Wallet set="curved" className="remix-icon" />,
    access: null,
    children: [
      {
        id: "saldo-withdrawal",
        title: "Withdraw",
        navLink: "/panel/saldo-profit/withdraw/data",
        access: null,
      },
      {
        id: "saldo-withdrawal-log",
        title: "Log Balance",
        navLink: "/panel/saldo-profit/withdraw/log-balance",
        access: null,
      },
      {
        id: "saldo-withdrawal-opname",
        title: "Opname",
        navLink: "/panel/saldo-profit/withdraw/opname",
        access: null,
      },
    ],
  },
  {
    id: "panel-saldo",
    title: "Saldo Member",
    icon: <IoCardOutline set="curved" />,
    access: null,
    children: [
      {
        id: "saldo-member-list",
        title: "List Member",
        navLink: "/panel/saldo-member/member",
        access: null,
      },
      {
        id: "log-balance",
        title: "Log Balance",
        navLink: "/panel/history-balance",
        access: null,
        // NOT SET
      },
      {
        id: "saldo-member-opname",
        title: "Opname",
        navLink: "/panel/saldo-member/opname",
        access: null,
      },
    ],
  },
  {
    id: "panel-config",
    title: "Konfigurasi",
    icon: <RiPencilLine set="curved" />,
    access: null,
    children: [
      {
        id: "panel-payment",
        title: "Payment Method",
        navLink: "/panel/payment-method/data",
        access: null,
      },
      {
        id: "panel-feature",
        title: "Fitur & Subscription",
        navLink: "/panel/feature",
        access: null,
      },
    ],
  },
  {
    header: "SUPPORT",
    access: ["59", "60", "63"],
  },
  {
    id: "request-ticket",
    title: "Tiket",
    icon: <Ticket set="curved" className="remix-icon" />,
    navLink: "/ticket/data",
    indicator: "ticket-indicator",
    access: ["59", "60"],
  },
  {
    id: "feedback",
    title: "Kritik dan Saran",
    icon: <Message set="curved" className="remix-icon" />,
    navLink: "/feedback",
    indicator: "feedback-indicator",
    access: ["63", "630"],
  },
  {
    header: "LAINNYA",
    access: ["44", "49", "53", "56", "57", "69"],
  },
  {
    id: "admin-management",
    title: "Manajemen Admin",
    icon: <User set="curved" />,
    access: ["44", "49", "48", "4401"],
    children: [
      {
        id: "admin-list",
        title: "List Admin",
        navLink: "/admin-list/data",
        access: ["44", "4401"],
      },
      {
        id: "role",
        title: "Role & Privilege",
        navLink: "/role/data",
        access: ["48", "49"],
      },
    ],
  },

  {
    id: "setting",
    title: "Pengaturan",
    icon: <Setting set="curved" />,
    access: ["56", "57", "69", "691", "66", "660", "67", "670"],
    children: [
      {
        id: "payment-method",
        title: "Metode Pembayaran",
        access: ["66", "660"],
        children: [
          {
            id: "payment-xendit",
            title: "Xendit",
            navLink: "/payment-method/xendit",
            access: ["66", "660"],
          },
          {
            id: "payment-manual",
            title: "Manual Transfer",
            navLink: "/payment-method/manual/data",
            access: ["66", "660"],
          },
        ],
      },
      // {
      //   id: "referral",
      //   title: "Komisi Referral",
      //   navLink: "/referral-commission",
      //   access: ["57", "570"],
      // },
      {
        id: "onboarding",
        title: "Onboarding",
        navLink: "/onboarding",
        access: ["67", "670"],
      },
      {
        id: "changelog",
        title: "Versi Aplikasi",
        navLink: "/changelog/data",
        access: null,
        // NOT SET
      },
      {
        id: "voucher-phone",
        title: "Nomor HP Voucher",
        navLink: "/voucher-phone-number/data",
        access: null,
        // NOT SET
      },
      {
        id: "profile",
        title: "Profil Saya",
        navLink: "/profile",
        access: ["69", "691"],
      },
    ],
  },
  {
    id: "trash",
    title: "Sampah",
    icon: <Delete set="curved" />,
    access: ["64", "641", "643"],
    children: [
      {
        id: "trash-category",
        title: "Kategori",
        navLink: "/trash/category",
        access: ["641", "6421"],
      },
      {
        id: "trash-product",
        title: "Produk",
        navLink: "/trash/product",
        access: ["643"],
      },
    ],
  },
  {
    id: "activity-log",
    title: "Log Aktifitas",
    icon: <TimeCircle set="curved" className="remix-icon" />,
    navLink: "/activity-log",
    access: ["53", "54"],
  },
];

export default pages;
